.addservices {
  position: relative;
  padding: 180px 0;

  &:before {
    content: '';
    background-image: url("../img/sc.jpg");
    background-position:  center center;
    background-size: cover;
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
    top:0;
    z-index: -1;
  }
  &:after {
    content: '';
    background-image: url("../img/tv.jpg");
    background-position:  center center;
    background-size: cover;
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
    top:0;
    z-index: -1;
  }
  h2 {
    margin-bottom: 100px;
  }
  h3 {

    &::after {
      content: '';
      width: 45px;
      height: 2px;
      display: block;
      background: $yellow;
    }
  }

  .addservices__block {
    position: relative;

    ul {
      text-transform: initial;
      padding: 0;
      margin: 0;
      list-style: none;

      & li {
        background: url("../img/bullet.png") left 8px no-repeat;
        padding-left: 20px;
        margin-bottom: 10px;
        color: #dadada;
      }
    }



    .wrap {
      display: flex;
      justify-content: space-between;

      & .addservices__item {
        width: 48%;
        text-transform: uppercase;
        font-weight: 300;
        background-size: cover;
      }
    }


  }
}

@media (max-width:810px) {
  .addservices .addservices__block .wrap {
    flex-direction: column;
  }
  .addservices .addservices__block .wrap .addservices__item {
    width: 100%;
  }
  .addservices .addservices__block .wrap .addservices__item:first-child {
    border-bottom: 1px solid #232323;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
  .addservices:after, .addservices:before {
    display: none;
  }
  .addservices {
    padding: 80px 0;
  }
  .addservices h2 {
    margin-bottom: 30px;
  }
}

@media (max-width:610px) {

}

