.header {
  height: 100vh;
  position: relative;
  z-index: 10;
  animation-delay: 0.3s;
  background: url("../img/avto-header.jpg") right 300px no-repeat;
   .header__wrap {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-top: 28px;
     padding-bottom: 28px;

     .header__logo {
       width: 268px;
       animation-delay: 0.3s;

       & img {
         width: 100%;
       }
     }
   }
  .title__wrap {
    padding-top: 45px;
    font-weight: 100;

    p {
      margin: 0;
      font-size: 22px;
      animation-delay: 1.6s;
      line-height: 1.364;
    }

    h1 {
      font-size: 50px;
      animation-delay: 1.3s;
      font-weight: 100;
      line-height: 1.2;
      text-transform: uppercase;

      span {
        color: $yellow;
        font-size: 55px;
      }
    }
  }
}

.header__phone {
  text-align: right;
  animation-delay: 0.6s;

  .header__number a {
    font-size: 35px;
    color: #fff;
    line-height: 28px;
    text-decoration: none;
    font-weight: 700;
    display: flex;

    span {
      font-size: 20px;
      font-weight: 300;
      margin-top: -5px;
      margin-right: 6px;
    }

    &:hover {
      color: darken(#fff, 20%);
    }
  }
  .header__callme a {
    color: $yellow;
    text-decoration: none;
    border-bottom: 1px solid;
    text-transform: uppercase;
    font-size: 12px;
    &:hover {
      border-color:transparent ;
    }
  }
}

.header .title__wrap .car__mobile, .header .header__burger {
  display: none;
}

@media (max-width: 1600px) {
  .header {
    background-size: 60% auto;
  }
}

@media (max-width: 1200px) {
  .header .title__wrap h1 {
    font-size: 40px;
  }
  .header .title__wrap h1 span {
    color: #ffbc00;
    font-size: 44px;
  }
}

@media (max-width: 991px) {
  .header {
    background: none;
  }
  .header .title__wrap {
    text-align: center;
    padding-top: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  .header .title__wrap .car__mobile {
    display: block;
    margin-top: 40px;
    animation-delay: 1.9s;
  }
  .header .title__wrap .car__mobile img {
    width: 80%;
    margin: 0 auto;
  }
  .header .title__wrap h1 {
    font-size: 40px;
  }
  .header .title__wrap h1 span {
    color: #ffbc00;
    font-size: 44px;
  }
  .header .title__wrap p {
    font-size: 18px;
  }
  .header__phone .header__number a {
    font-size: 25px;

    span {
      font-size: 14px;
    }
  }
  .header .header__wrap .header__logo {
    width: 200px;
  }
  .header__phone .header__callme a {
    font-size: 10px;
  }
}

@media (max-width: 810px) {
  .header .header__burger {
    display: block;

    button.burger {
      display: block;
      position: fixed;
      animation-delay: .6s;
      top: 56px;
      right: 32px;
      outline: none;
      background-color: transparent;
      border: none;
      appearance: none;
      z-index: 1600;
    }

    button.burger span.box {
      display: block;
      position: absolute;
      width: 26px;
      height: 26px;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: pointer;
      transition: .3s;
    }

    button.burger span.box span.bar {
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #fff;
      position: absolute;
      transition: .5s;
      border-radius: 3px;
    }

    button.burger span.box span.bar.top {
      top: 4px;
    }

    button.burger span.box span.bar.middle {
      top: 50%;
      margin: -1px 0 0;
    }

    button.burger span.box span.bar.bottom {
      bottom: 3px;
    }

    button.burger.active span.box span.bar {
      width: 100% !important;
      background-color: #fff;
    }

    button.burger.active span.box span.bar.top {
      -webkit-transform: rotate(45deg) translate(8px, 7px);
      -ms-transform: rotate(45deg) translate(8px, 7px);
      transform: rotate(45deg) translate(8px, 7px);
      top: 0;
    }

    button.burger.active span.box span.bar.middle {
      opacity: 0;
    }

    button.burger.active span.box span.bar.bottom {
      -webkit-transform: rotate(-45deg) translate(8px, -7px);
      -ms-transform: rotate(-45deg) translate(8px, -7px);
      transform: rotate(-45deg) translate(8px, -7px);
      bottom: 2px;
    }
  }
}

@media (max-width: 500px) {
  .header .header__wrap {
    flex-direction: column;
  }
  .header .header__wrap .header__logo {
    margin-bottom: 15px;
  }
  .header__phone {
    text-align: center;
  }
  .header .title__wrap .car__mobile img {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 450px) {
  .header .title__wrap {
    top: 63%;
    font-weight: 300;
  }

  .header .header__wrap .header__logo {
    width: 150px;
    align-self: flex-start;
  }

  .header .title__wrap p {
    font-size: 14px;
  }

  .header .title__wrap h1 {
    font-size: 22px;
    font-weight: 300;
  }

  .header .title__wrap h1 span {
    color: #ffbc00;
    font-size: 24px;
  }
}