.about {
  background: url("../img/about-bg.jpg") center center no-repeat;
  background-size: cover;
  padding: 230px 0;

  span {
    color: $yellow;
  }
}

@media (max-width:610px) {
  .about {
    padding: 80px 0;
  }
  .about h2 {
    text-align: center;
  }
  .about h2::after {
    margin: 0 auto;
  }
}

