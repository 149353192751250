.bann__block {
  background: $yellow;
  padding: 42px 0;
  color: $black;
  font-size: 40px;
  text-transform: uppercase;

  h4 {
    margin: 0;
    font-weight: 700;
    line-height: 46px;
  }

  &.bann__block__t .bann__wrap {
    flex-direction: column;
    text-align: center;

    h2::after {
      background: $black;
    }

    h4 {
      font-size: 22px;
      margin-bottom: 20px;
      line-height: 30px;
    }
  }

  &.bann__block__t h4 {
    font-size: 30px;
    text-transform: initial;
    line-height: 37px;
  }

  .bann__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bann_btn {
    border-radius: 3px;
    background: linear-gradient(0deg, #b01616 0%, rgb(255,0,0) 100%);
    box-shadow: inset 0 1px 0 #ffabab, 0 4px 12px #760000;
    color: #fff;
    padding: 15px 44px;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
    text-decoration: none;

    &:hover {
      box-shadow: inset 0 1px 0 #ffabab, 0 2px 5px #760000;
    }
  }
  a.telphone {
    color:#000;
    text-decoration: none;
  }
}



@media (max-width: 1200px) {
  .bann__block {
    font-size: 30px;
    padding: 32px 0;
    line-height: 38px;
  }
  .bann__block h4 {
    line-height: 38px;
  }
}

@media (max-width: 810px) {
  .bann__block .bann__wrap {
    flex-direction: column;
    text-align: center;
  }

  .bann__block h4 {
    margin-bottom: 20px;
  }
  .bann__block .bann_btn {
    padding: 13px 34px;
    font-size: 18px;
  }
}
@media (max-width: 610px) {
  .bann__block h4 {
    line-height: 30px;
    font-size: 25px;
  }
  .bann__block.bann__block__t .bann__wrap h2 {
    font-size: 28px;
    line-height: 35px;
  }
  .bann__block.bann__block__t .bann__wrap h4 {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 25px;
  }
  .bann__block.bann__block__t .bann__wrap h2::after {
    margin-top: 10px;
  }
  .bann__block a.telphone {
    display: block;
  }
  .bann__block .banner_btt br {
    display: none;
  }
}