@charset "UTF-8";

/**
 * Основные стили
 **/

html {
	font-size: 18px;
	color: $white;
}

body {
	font-family: 'SF UI Display', 'Arial', sans-serif;
	font-weight: 400;
	overflow-wrap: break-word;
	word-wrap: break-word;
	background: $bg;
	line-height: 1.667;

	&.over_hide {
		overflow: hidden;
	}
}

.text-center {
	text-align: center;
}

h2 {
	font-size: 40px;
	padding: 0 15px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 70px;
	margin: 0 0 20px 0;

	&::after {
		content: '';
		width: 45px;
		height: 2px;
		display: block;
		background: $yellow;
	}

	&.text-center {
		text-align: center;

		&::after {
			margin: 0 auto;
		}
	}
}

a {
	transition: all .2s ease;
}

.container {
	@include min-max(320px, 100%, 0);
	overflow: hidden;
}

.wrap {
	@include min-max(320px, 1200px, 15px);
}

.wrap__s {
	@include min-max(320px, 600px, 15px);
}

@media (max-width: 810px) {
	h2 {
		font-size: 32px;
		line-height: 60px;
	}
}

@media (max-width: 610px) {

	html {
		font-size: 15px;
	}
	h2 {
		font-size: 23px;
		line-height: 40px;
	}
}