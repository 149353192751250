.form__block {
  position: relative;

  input[type="text"] {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #424242;
    padding: 10px 0;
    outline: none;
    color: #fff;
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
  }
  textarea {
    background: transparent;
    border: 0;
    border-bottom: 1px solid #424242;
    padding: 10px 0;
    height: 100px;
    resize: none;
    outline: none;
    color: #fff;
    width: 100%;
    font-size: 14px;
    margin-bottom: 20px;
  }

  input[type="button"] {
    background: $yellow;
    border-radius: 3px;
    border: 2px solid $yellow;
    cursor: pointer;
    padding: 10px 0;
    text-align: center;
    color: #000;
    outline: none;
    text-transform: uppercase;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    transition: all .2s ease;

    &:hover {
      background: transparent;
      color: $yellow;
    }
  }

  input {
    width: 100%;
  }
}
.messages-block {
  height: 100%;
  width: 100%;

  & .susses {
    color: #ffffff;
    text-align: center;
    font-size: 17px;
    line-height: 33px;
    background: rgb(93, 154, 57);
    border-radius: 3px;
    margin-bottom: 5px;
    width: 100%;
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: center;
    font-weight: 100;
    padding: 6px 15px;
    align-items: center;
  }

  & .error {
    color: #c7c7c7;
    font-size: 13px;
    background: #863838;
    border-radius: 3px;
    margin-bottom: 5px;
    width: 100%;
    display: block;
    position: relative;
    font-weight: 300;
    padding: 1px 15px;
  }
}
