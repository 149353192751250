.menu__wrap {
   nav ul {
     display: flex;
     animation-delay: 0.9s;
     justify-content: center;
     list-style: none;
     margin: 25px 0;
     padding: 0;
     line-height: 1.2;

     li {
       margin: 0 30px;

       a {
         color: #dadada;
         font-size: 16px;
         border-bottom: 1px solid transparent;
         text-transform: uppercase;
         text-decoration: none;

         &:hover {
           color: $yellow;
           border-color: $yellow;
         }
       }
     }
   }
}

@media (max-width: 991px) {
  .menu__wrap nav ul li {
    margin: 0 15px;
  }
}

@media (max-width: 810px) {
  .menu__wrap nav ul {
    flex-direction: column;
    height: 100%;
    text-align: center;
    margin: 0;
  }
  .menu__wrap nav ul li {
    margin: 20px 0;
  }
  .menu__wrap nav ul li a {
    font-size: 24px;
    margin: 20px 0;
  }
  .menu__wrap nav {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    box-shadow: 0 0 3px rgba(42, 42, 42, 0.1);
    display: block;
    z-index: 1500;
    transition: 0.7s cubic-bezier(0.86, 0, 0.07, 1);
  }

  .menu__wrap nav.active {
    right: 0;
    display: block;
  }
}

@media (max-width:450px) {
  .header .header__burger button.burger {
    top: 47px;
  }
}