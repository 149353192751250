.cars {
  padding: 180px 0;
  background: #131313;

  h2 {
    margin-bottom: 100px;
  }

  .cars__block {
    display: grid;
    grid-template-columns: repeat(auto-fill, 31%);
    grid-gap: 1rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;

    .cars__element {
      height: 270px;
      background-size: cover;
      background-position: center center;
      width: 100%;
      margin-bottom: 25px;
      display: flex;
      align-items: flex-end;
      box-shadow: 0 3px 9px #000;
      border-radius: 10px;
      position: relative;

      &::after {
        content: '';
        background-image: linear-gradient(to bottom,transparent 0%,rgba(0,0,0,.8) 70%,rgba(0,0,0,.9) 90%);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top:0;
      }

      .cars__info {
        position: relative;
        z-index: 2;
        width: 100%;
        padding: 15px;

        .cars__title {
          font-size: 22px;
          color: #fff;
          text-transform: uppercase;
        }
        .cars__price__buy {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-transform: uppercase;
          color: $yellow;
          font-size: 18px;
          margin-bottom: 20px;

          .buy__btn {
            padding: 4px 12px;
            border-radius: 3px;
            border: 1px solid $yellow;
            color: $yellow;
            font-size: 14px;
            text-decoration: none;

            &:hover {
              color: $black;
              background: $yellow;
            }
          }
        }
        ul.cars__specifications {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          align-items: center;
          font-size: 14px;

          li {
            height: 20px;
            display: flex;
            margin-left: 15px;
            align-items: center;

            img {
              height: 20px;
              margin-right: 10px;
            }

            &:first-child {
              margin-left: 0;
            }
          }

          li.cars__transmission {
          }
          li.cars__option {
          }
          li.cars__fuel {
          }
        }
      }
    }
  }
}

@media (max-width:910px) {
  .cars .cars__block {
    grid-template-columns: repeat(auto-fill,48%);
  }
}
@media (max-width:769px) {
  .cars .cars__block {
    grid-template-columns: repeat(auto-fill,100%);
  }
  .cars {
    padding: 80px 0;
  }
  .cars h2 {
    margin-bottom: 30px;
  }
}
@media (max-width:610px) {
  .cars .cars__block .cars__element .cars__info .cars__price__buy {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .cars .cars__block .cars__element .cars__info .cars__title {
    font-size: 20px;
  }
  .cars .cars__block .cars__element .cars__info ul.cars__specifications {
    font-size: 13px;
  }
}