.footer {
  padding: 30px 0 15px;

  .footer__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .footer__logo .text-center {
    margin-top: 0;
  }
  .text-center {
    color: #4b4b4b;
    margin-top: 40px;
    font-size: 14px;
  }
}

@media (max-width:450px) {
  .footer .footer__top {
    flex-direction: column;
  }
  .footer .footer__top .footer__logo {
    margin-bottom: 15px;
  }
}