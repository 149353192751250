.advantages {
  padding: 180px 0;

  h2 {
    margin-bottom: 100px;
  }

  .advantages__block {
    background: url("../img/advantages.jpg") center center no-repeat;
    min-height: 666px;
    display: flex;
    justify-content: space-between;

    & .advantages__item {
      width: 36%;
      text-transform: uppercase;
      font-weight: 300;

      .advantages__element {
        position: relative;
        margin-bottom: 30px;

        &::after {
          content: '';
          background: url("../img/border-y.png") repeat-x;
          height: 1px;
          display: block;
          margin-top: 10px;
        }

        &::before {
          content: '';
          background: $yellow;
          height: 12px;
          width: 12px;
          position: absolute;
          display: block;
          border-radius: 100%;
          right: -7px;
          bottom: -5px;
        }
      }

      &:last-child {
        text-align: right;

        .advantages__element {

          &::before {
            right: auto;
            left: -7px;
          }
        }
      }
    }
  }
}

@media (max-width:810px) {
  .advantages .advantages__block {
    background: url(../img/advantages-pos.jpg) center center no-repeat;
  }
  .advantages .advantages__block .advantages__item {
    width: 48%;
  }
}

@media (max-width:610px) {
  .advantages .advantages__block {
    flex-direction: column;
    justify-content: flex-start;
  }
  .advantages {
    padding: 80px 0;
  }
  .advantages .advantages__block .advantages__item {
    width:100%;
  }
  .advantages .advantages__block .advantages__item:last-child {
    text-align: left;
  }
  .advantages .advantages__block .advantages__item br {
    display: none;
  }
  .advantages .advantages__block .advantages__item .advantages__element::before, .advantages .advantages__block .advantages__item:last-child .advantages__element::before {
    display: none;
  }
  .advantages .advantages__block .advantages__item .advantages__element::after {
    opacity: 0.5;
  }
  .advantages .advantages__block .advantages__item .advantages__element {
    margin-bottom: 15px;
  }
  .advantages h2 {
    margin-bottom: 30px;
  }
}

